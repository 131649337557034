import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Menu } from 'primeng/menu';
import { Observable, Subject, tap } from 'rxjs';
import { HuddleService } from 'src/app/main/services/huddle.service';
import { AppState } from 'src/app/main/store/model/state.model';
import {
  App,
  Carpool,
  DialogEvent,
  SmartInstructions,
  UserDetails,
} from 'src/app/main/types/main.types';
import { carpoolOptions } from 'src/app/main/utilities/overlayHelper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carpool-overview',
  templateUrl: './carpool-overview.component.html',
})
export class CarpoolOverviewComponent implements OnInit {
  isLoading = false;

  isDark = false;

  isSkeletonLoading = false;

  carpool: Carpool;

  hasHuddle = false;

  userInfo: UserDetails;

  instructions: SmartInstructions;

  carpoolOptions = carpoolOptions;

  userDetail$: Observable<UserDetails>;

  shareLinkEvent: Subject<boolean> = new Subject<boolean>();

  profilePicUrl = '';

  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  appState$: Observable<App>;

  @ViewChild('menu', { static: false }) menuRef: Menu;

  @ViewChild('cd', { static: false }) confirmDialog: ConfirmDialog;

  constructor(
    private huddleService: HuddleService,
    private store: Store<AppState>,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
          this.getCarpool(0, {
            timeline: 'UPCOMING',
            membership: 'ALL',
            tripType: 'ALL',
          });
        }),
      );
    this.userDetail$.subscribe();

    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
        }),
      );
    this.appState$.subscribe();
  }

  openMenu(e: any, carpool: Carpool) {
    this.hasHuddle = !carpool.parentObj;
    this.carpool = carpool;
    this.menuRef.toggle(e);
  }

  getCarpool(page: number, data: any) {
    this.isLoading = true;
    this.huddleService.getAllCarpools(page, data, 1).subscribe({
      next: (data: any) => {
        if (data.content.length) {
          this.carpool = data.content[0];
          this.profilePicUrl = `${environment.apiUrl}/api/user/${this.carpool.leader.id}/pic`;
          this.getCarpoolInstructions();
        }
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Request to get vehicles errored out.' + err.message);
      },
    });
  }

  openMap(location: string | undefined) {
    window.open(
      'https://www.google.com/maps/dir/?api=1&z=12&destination=' +
        encodeURIComponent(location as string),
      '_blank',
    );
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  redirect(path: string[], itemId: string | null = null) {
    if (
      ['open_carpool', 'open_huddle', 'huddle_huddles'].includes(
        itemId as string,
      )
    )
      this.router.navigate(path);
    else if (itemId === 'share_link') {
      this.shareLinkEvent.next(true);
    }
  }

  getCarpoolInstructions() {
    const carpooler = this.carpool.carpoolers.find(
      (c) => c.member.id === this.userInfo.id,
    );
    this.isSkeletonLoading = true;
    this.huddleService
      .getCarpoolInstructions(this.carpool.id, carpooler?.id)
      .subscribe({
        next: (data: any) => {
          this.instructions = data;
          this.isSkeletonLoading = false;
        },
        error: (err) => {
          this.isSkeletonLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'AI Instructions',
            detail: err?.error
              ? err.error.message
              : 'Error while fetching AI instructions',
          });
        },
      });
  }

  onCarpool() {
    this.confirmationService.confirm({
      accept: () => {},
      reject: () => {},
    });
  }

  onConfirm(link?: string) {
    if (link) {
      this.router.navigate([link], {
        queryParams: { activity: 'general', features: 'carpooling' },
      });
    } else {
      this.dialogEvent.next({
        visible: true,
        dialogType: 'CARPOOL',
        isCreate: true,
        isViewMode: false,
      });
    }
    this.confirmationService.close();
  }
}
